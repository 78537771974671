import * as React from 'react';
import Layout from "../components/layout/layout"


function OutraPagina() {


    return <Layout><p>Wololo</p></Layout>
}

export default OutraPagina 